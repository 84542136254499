import React from "react";
import Map from "../../components/MapGoogle";

const Hero = () => {
  return (
    <div className="pt-23 pt-md-25 pt-lg-30 pb-12 pb-md-16 pb-lg-24">
      <div className="container">
        {/* Section Padding */}
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-8 col-md-11">
            <div
              className="text-center mb-11 mb-lg-24"
              data-aos="fade-up"
              data-aos-duration={200}
              data-aos-delay={100}
            >
              <h2 className="font-size-11 mb-5">Contact us</h2>
              <p className="font-size-7 mb-0">
                Get in touch with us for more enquiries.
              </p>
            </div>
          </div>
        </div>
        {/* End Section Padding */}
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div
              className="rounded-8"
              data-aos="fade-up"
              data-aos-duration={300}
              data-aos-delay={100}
            >
              <Map  />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
