import React from 'react';

const Content = () => {
  const widgetArray = [
    {
      title:
        "Digital Address: GM-048-9710"
    },
    {
      title: 'Monday through Friday',
    },
    {
      title: '8:00 a.m. to 5:00 p.m. GMT'
    },
  ];
  return (
    <>
      {/* Contact Form */}
      <div className="row justify-content-center pb-10 pb-md-14 pb-lg-23">
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 order-1 order-lg-1">
          <div className="pr-md-20 pr-xl-5">
            {/* Section Title */}
            <div
              className="mb-5 mb-lg-13 pr-md-15 pr-lg-10 pr-xl-0"
              data-aos="fade-right"
              data-aos-delay={100}
            >
              <h2 className="font-size-10 mb-8">
                Location
              </h2>
              <p className="font-size-6 mb-0">
                Visit our office at North Legon, Accra behind Albany Specialist Clinic. <br/>Our representatives are available to assist you{' '}
              </p>
            </div>
            {/* End Section Title */}
            <div className="list" data-aos="fade-right" data-aos-delay={300}>
              {widgetArray.map(({ title, icon }, index) => {
                return (
                  <div className="d-flex align-items-center mb-6" key={index}>
                    <p
                      className="font-size-7 font-weight-medium mb-0"
                      dangerouslySetInnerHTML={{ __html: title }}
                    ></p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div
          id="contact-form"
          className="col-xl-4 col-lg-5 col-md-6 col-sm-7 order-2 order-lg-2"
        >
          {/* contact details */}
          <div
            className="top-contact-info bg-default-1 max-w-540 mx-auto py-10 px-8 rounded-10"
            data-aos="fade-up"
            data-aos-duration={300}
            data-aos-once="true"
          >
            <div className="row">
              <div className="col-lg-5 mb-5 mb-lg-0">
                <div className="border-md-right border-cornflower-blue">
                  <h4 className="font-size-5 text-default-color font-weight-normal mb-0">
                    Call us
                  </h4>
                  <a
                    className="font-size-6 font-weight-bold"
                    href="tel:+233509660505"
                  >
                    +233(0)509660505
                    <br />
                    +233(0)556814691
                  </a>
                </div>
              </div>
              <div className="col-lg-7 mb-5 mb-lg-0">
                <div className="pl-1 pr-3">
                  <h4 className="font-size-5 text-default-color font-weight-normal mb-0">
                    Email us
                  </h4>
                  <a
                    className="font-size-6 font-weight-bold"
                    href="mailto:info@omnistrategies.net"
                  >
                    info@ocorie.com
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* contact details */}
          {/* <div className="pt-12 pb-10 max-w-536 mx-auto">
            <form
              name="contact"
              method="POST"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              data-aos="fade-up"
              data-aos-duration={400}
              data-aos-once="true"
            >
              <input type="hidden" name="form-name" value="contact" />
              <div className="form-group mb-7 position-relative">
                <input
                  type="text"
                  name="name"
                  className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color-4"
                  placeholder="Full Name"
                  id="company"
                  required
                />
              </div>
              <div className="form-group mb-7 position-relative">
                <input
                  type="email"
                  name="email"
                  className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color-4"
                  placeholder="Email Address"
                  id="email"
                  required
                />
              </div>
              <div className="form-group mb-7 position-relative">
                <input
                  type="text"
                  name="subject"
                  className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color-4"
                  placeholder="Subject"
                  id="subject"
                  required
                />
              </div>
              <div className="form-group mb-7 position-relative">
                <textarea
                  name="message"
                  id="message"
                  placeholder="Type your message"
                  className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 pt-7 font-size-5 border-default-color-4"
                  defaultValue={''}
                  required
                />
              </div>
              <div className="button">
                <button
                  type="submit"
                  href="/#"
                  className="btn btn-blue-3 h-55 w-100 rounded-4"
                >
                  Send
                </button>
              </div>
            </form>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Content;
